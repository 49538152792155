<template>
	<div class="jobhunting">
		<Header style="display:inline-block;" activeUrl="/findEnterprise"></Header>
		<div class="job-position">
			<div class="position-content">
				<div class="center">
					<div class="position-tab">
						<div class="sort_order">
							<div class="position-tab-a" v-for="(Sitem, Sindex) in sortList" :key="Sindex">
								<div class="title">{{ Sitem.name }}：</div>
								<div class="info flex1">
									<cu-tab-select :data="Sitem.data" v-model="Sitem.clcik" @change="search()">
									</cu-tab-select>
								</div>
							</div>
						</div>
						<div class="position-tab-content">
							<div class="resumelist">
								<div class="resume flex2" v-for="(resumeItem, resume) in PerJobList" :key="resume">
									<div class="resumeLeft flexc" @click="talk('/company1', { id: resumeItem.coM_ID })
                                    ">
										<div class="left">
											<div class="pic">
												<el-image fit="contain" v-if="resumeItem.coM_LOGO"
													:src="setuserlogo(resumeItem.coM_LOGO)"></el-image>
												<el-image fit="contain" v-else :src="require('@/assets/img/logos.png')">
												</el-image>
											</div>
											<div class="personal">
												<div class="name flex1">
													<div
														style="color: rgba(102, 102, 102, 100);font-size: 16px;font-family: SourceHanSansSC-regular;">
														{{ resumeItem.coM_NAME }}
													</div>
												</div>
												<div class="company_profile">
													<span>
														<i class="el-icon-menu" style="color:#898989"></i>
														{{resumeItem.coM_INDUSTRY==null?"其他":resumeItem.coM_INDUSTRY}}</span>
													<span>
														<i class="el-icon-price-tag"></i>
														{{ resumeItem.coM_NATURE }}</span>
													<span>
														<i class="el-icon-location-outline"></i>
														{{resumeItem.coM_ADDRESS==null?"临海":resumeItem.coM_ADDRESS}}</span>
													<span>
														<i class="el-icon-user"></i>
														{{ resumeItem.coM_SCALE }}</span>
												</div>
												<div class="flex1">
													<div class="hot_move">
														<span>热招：</span>
														<div class="zheye">
															<a v-for="(itemrz,indexrz) in getrz(resumeItem.info)"
																:key="indexrz">{{itemrz}}</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="resumeRight">
										<div v-if="idcompanylogin">
											<div v-if="resumeItem.isFav == 0
											" @click="favuser(resumeItem.coM_ID)" class="follow">
												+ 关注
											</div>
											<div @click="cancelfavuser(resumeItem.coM_ID)" v-else class="follow">
												已关注
											</div>
										</div>
										<span>详情>></span>
									</div>
								</div>
							</div>
							<cu-pager :form="form" :total="total" @change="handleCurrentChange"
								@sizeChange="handleSizeChange"></cu-pager>
						</div>

						<el-empty style="background: #fff" v-if="PerJobList.length < 1" :image-size="200"></el-empty>
					</div>
					<div class="recent">
						<div class="recentBrowsing">
							<div class="title">
								<span>岗位推荐</span>
							</div>
							<div class="occupation" v-for="(item,index) in hotJobLit" :key="index">
								<div class="occupation_company">
									<div>{{item.oF_POSI_NAME}}</div>
									<div style="color: rgba(137, 137, 137, 100);margin-top:15px;">{{item.coM_NAME}}</div>
								</div>
								<div class="salary">
									<span>{{setsalary(item.oF_SALARY,item.oF_SALARYE,item.oF_SALARY_MY)}}</span>
								</div>
							</div>

							<!-- <div class="occupation">
								<div class="occupation_company">
									<div>数据分析师</div>
									<div style="color: rgba(137, 137, 137, 100);margin-top:15px;">金华六和机械有限公司</div>
								</div>
								<div class="salary">
									<span>12K-18K</span>
								</div>
							</div>

							<div class="occupation">
								<div class="occupation_company">
									<div>web前端工程师</div>
									<div style="color: rgba(137, 137, 137, 100);margin-top:15px;">金华六和机械有限公司</div>
								</div>
								<div class="salary">
									<span>12K-18K</span>
								</div>
							</div>

							<div class="occupation">
								<div class="occupation_company">
									<div>视觉设计师</div>
									<div style="color: rgba(137, 137, 137, 100);margin-top:15px;">金华六和机械有限公司</div>
								</div>
								<div class="salary">
									<span>12K-18K</span>
								</div>
							</div>

							<div class="occupation">
								<div class="occupation_company">
									<div>UI设计师</div>
									<div style="color: rgba(137, 137, 137, 100);margin-top:15px;">金华六和机械有限公司</div>
								</div>
								<div class="salary">
									<span>12K-18K</span>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>

		<Footer></Footer>
	</div>
</template>

<script>
	import {
		Getindexsearch,
		Addresume,
		Getposilist,
		Getcompanylist,
		AddCall,
		CancelCall,
		GethotposilistPage
	} from "@/api/home";
	import {
		isnull,
		handleCofirm,
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	import Header from "@/components/Header2.vue";
	import Footer from "@/components/footer1.vue";
	import CuTabSelect from '../../components/CuTabSelect.vue';
	import CuPager from '../../components/CuPager.vue';
	export default {
		components: {
			Header,
			Footer,
			CuTabSelect,
			CuPager
		},


		data() {
			return {
				defimgurl: global.baseimgurl,
				form: {
					searchKey: "",
					pageNumber: 1,
					pageSize: 7,
				},
				total: 0,
				cur: 0,
				PerJobList: [],
				curr: 0,

				educationlist: [], //学历
				isfieldchage: false,
				field1: 18,
				stateyear: "",
				field2: 80,
				endyear: "",
				user: {},
				hotJobLit:[],
				sortList: [
					{
						clcik: "",
						name: "排序方式",
						data: [{
								name: "默认",
								value: ""
							},
							{
								name: "职位数量",
								value: "职位数量"
							},

						],
					},
				],
				searcList: [{
						clcik: "",
						name: "公司地点",
						data: [{
								name: "全部",
								value: ""
							},
							{
								name: "云山街道",
								value: "云山街道"
							},
							{
								name: "兰江街道",
								value: "兰江街道"
							},
							{
								name: "上华街道",
								value: "上华街道"
							},
							{
								name: "永昌街道",
								value: "永昌街道"
							},
							{
								name: "赤溪街道",
								value: "赤溪街道"
							},
							{
								name: "女埠街道",
								value: "女埠街道"
							},
							{
								name: "游埠镇",
								value: "游埠镇"
							},
							{
								name: "诸葛镇",
								value: "诸葛镇"
							},
							{
								name: "马涧镇",
								value: "马涧镇"
							},
							{
								name: "香溪镇",
								value: "香溪镇"
							},
							{
								name: "黄店镇",
								value: "黄店镇"
							},
							{
								name: "梅江镇",
								value: "梅江镇"
							},
							{
								name: "横溪镇",
								value: "横溪镇"
							},
							{
								name: "柏社乡",
								value: "柏社乡"
							},
							{
								name: "灵洞乡",
								value: "灵洞乡"
							},
							{
								name: "水亭畲族乡",
								value: "水亭畲族乡"
							},
						],
					},
					{

						name: "行业领域",
						data: [{
								name: "全部",
								value: ""
							}, {
								name: "电子商务",
								value: "电子商务"
							}, {
								name: "互联网",
								value: "互联网"
							}, {
								name: "金融",
								value: "金融"
							}, {
								name: "教育",
								value: "教育"
							}, {
								name: "旅游",
								value: "旅游"
							}, {
								name: "医疗",
								value: "医疗"
							}


						],
					},
					{

						clcik: "",
						name: "公司规模",
						data: [{
								name: "全部",
								value: ""
							},
							{
								name: "少于15人",
								value: 1
							},
							{
								name: "15-50人",
								value: 2
							},
							{
								name: " 50-100人",
								value: 3
							},
							{
								name: "100-200人",
								value: 4
							},
							{
								name: " 200-500人",
								value: 5
							},
							{
								name: " 500人以上",
								value: 6
							},
						],
					},
					{
						clcik: "",
						name: "公司性质",
						data: [{
								name: "全部",
								value: ""
							},
							{
								name: "私企",
								value: 1
							},
							{
								name: "国企",
								value: 2
							},
							{
								name: " 个体户",
								value: 3
							},
							{
								name: "上市",
								value: 4
							},
							{
								name: "事业单位",
								value: 4
							},

						],
					},
				],
				idcompanylogin: false,
			};
		},
		watch: {
			field1(value, o) {
				if (value < 18) {
					this.field1 = 18;
				} else if (value > 80) {
					this.field1 = 80;
				} else if (value > this.field2) {
					this.field1 = this.field2;
				} else {
					this.field1 = value;
				}
			},
			field2(value, o) {
				if (value < 18) {
					this.field2 = 18;
				} else if (value > 80) {
					this.field2 = 80;
				} else if (value < this.field1) {
					this.field2 = this.field1;
				} else {
					this.field2 = value;
				}
			},
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true);
		},
		created() {
			if (localStorage.getItem("userinfo")) {
				this.user = JSON.parse(localStorage.getItem("userinfo"));
				if (this.user.peR_ID) {
					this.idcompanylogin = true;
				}
			}
			this.form.searchKey = this.$route.query.searchKey;
			this.GetList();
			GethotposilistPage({PageSize:10}).then((res)=>{
				 this.hotJobLit=res.data.rows;
			})
			//this.Getposilists();
		},
		methods: {
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			enter(i) {
				this.curr = i;
			},
			leave() {
				this.curr = null;
			},
			goPage(path) {
				this.$router.push(path);
			},
			SelectTap(i, j) {
				this.searcList[i].clcik = this.searcList[i].data[j].value;
				this.form.pageNumber = 1;
				this.GetList();
			},
			search() {
				this.form.pageNumber = 1;
				this.GetList();
			},
			yearselect() {
				var time = new Date();
				let year = time.getFullYear();
				this.stateyear = year - this.field1;

				this.endyear = year - this.field2;
				this.form.pageNumber = 1;
				this.GetList();
			},
			//企业列表
			GetList() {
				var _this = this;
				var par = {
					searchKey: _this.form.searchKey,
					pageNumber: _this.form.pageNumber,
					pageSize: _this.form.pageSize,
					uid: this.user.peR_ID,
					order:_this.sortList[0].clcik
				};
				Getcompanylist(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						});
						_this.total = res.data.total;
						_this.PerJobList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//人才收藏
			favuser(id) {
				var _this = this;
				var par = {
					id: id,
					uid: this.user.peR_ID,
				};
				AddCall(par).then((res) => {
					if (res.success) {
						this.GetList();
						_this.$message.success("企业已关注!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//取消人才收藏
			cancelfavuser(id) {
				var _this = this;
				handleCofirm('确认取消关注该企业吗？', 'warning', ).then(res => {
					var par = {
						id: id,
						uid: this.user.peR_ID,
					};
					CancelCall(par).then((res) => {
						if (res.success) {
							this.GetList();
							_this.$message.success("已取消关注!");
						} else {
							_this.$message.error(res.msg);
						}
					});
				}).catch(err => {
					//console.log('err', err) // cancel
				})
			},
			handleSizeChange(val) {
				this.form.pageNumber = 1;
				this.form.pageSize = val;
				this.GetList();
				this.backtop();
			},
			handleCurrentChange(val) {
				this.form.pageNumber = val;
				this.GetList();
				this.backtop();
			},
			setsalary(a, b) {
				return utils.setsalary(a,b);

			},

			backtop() {
				var timer = setInterval(function() {
					let osTop =
						document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
						osTop + ispeed;
					this.isTop = true;
					if (osTop === 0) {
						clearInterval(timer);
					}
				}, 30);
			},

			talk(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},

			Getposilists() {
				var _this = this;
				Getposilist({
					types: "行业"
				}).then((res) => {
					if (res.success) {
						_this.educationlist = res.data.rows;
						res.data.rows.forEach((element) => {
							if (element.cName != "不限") {
								this.searcList[1].data.push({
									name: element.cName,
									value: element.cCode,
								});
							}
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			setOFEDUREQ(data) {
				return isnull(data) ? "不限" : data;
			},
			clearsel() {
				this.form = {
					searchKey: "",
					pageNumber: 1,
					pageSize: 10,
				};
				this.GetList();
			},

			salary(data) {
				if (data.indexOf("-") == -1) {
					return "面议";
				} else {
					return data;
				}
			},
			getrz(item) {
				let arr = [];
				if (item != null && item != '') {
					arr = item.split(',');
				}
				return arr;
			},
			setuserlogo(url) {
				var data = url.indexOf("http");

				return isnull(url) ?
					require("@/assets/img/logos.png") :
					data < 0 ?
					this.defimgurl + url :
					url;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.job-search {
		position: relative;
		width: 100%;
		background: #fff;
		border-top: 1px solid #eee;
		margin-bottom: 16px;

		.search-content {
			width: 1200px;
			margin: 0px auto;
			padding: 15px 0px;
			display: flex;
			justify-content: space-between;

			.search {
				flex: 1;
				width: 100%;

				.el-form {


					.el-form-item ::v-deep {
						margin-bottom: 0;

						.el-form-item__content {
							display: flex;
							align-items: stretch;

							.search-input {
								display: flex;
								border: 1px solid #e5e5e5;

								.el-input {
									.el-input__inner {
										border: none;
										width: 600px;
									}
								}
							}

							.search-btn {
								.el-button {
									background: #66b1ff;
									border-radius: 0;
									height: 100%;
									padding: 12px 30px;
									width: 150px;
								}
							}
						}
					}
				}
			}

			.search-hottel {
				display: flex;
				align-items: center;
				font-size: 14px;

				img {
					width: 22px;
					height: 22px;
					margin-right: 4px;
				}

				span {
					font-size: 18px;
					color: #66b1ff;
				}
			}
		}

		.select-content {
			width: 1200px;
			margin: 0px auto;
			padding-bottom: 15px;
			display: flex;
			justify-content: space-between;

			.select-cell {
				.el-form {
					.el-form-item ::v-deep {
						margin-bottom: 0;

						.select-input {
							.el-select {
								margin-right: 60px;

								.el-input__inner {
									width: 110px;
									padding-left: 0;
									border: none;
								}
							}
						}
					}
				}
			}

			.select-clear {
				font-size: 14px;
				color: #787878;
				line-height: 40px;
				cursor: pointer;
			}
		}
	}

	.job-position {
		position: relative;
		width: 1200px;
		margin: 0px auto;
		margin-bottom: 20px;

		.position-content {
			.position-tab-title {
				background: #fff;
				margin-bottom: 20px;
				padding: 20px 20px 1px 20px;

				.position-tab-a {
					display: flex;
					font-size: 14px;
					margin-bottom: 20px;

					.title {
						font-weight: bold;
						color: #333;
						padding: 5px 10px;
						min-width: 90px;
					}

					.info {
						span {
							margin: 0 20px;
						}

						.info-a {
							cursor: pointer;
							padding: 5px 10px;
							margin-right: 10px;
						}

						.info-Select {
							color: #fff;
							background-color: #2778f8;
							border-radius: 4px;
						}
					}

					.el-dropdown-link {
						cursor: pointer;
					}
				}
			}

			.center {
				display: flex;

				.position-tab {
					position: relative;
					// display: flex;
					align-items: stretch;
					width: 70%;

					.sort_order {
						width: 100%;
						height: 28px;
						background: #fff;
						margin-bottom: 20px;
						display: flex;
						padding-top: 10px;
						padding-bottom: 10px;

						//   box-sizing: border-box;
						.position-tab-a {
							display: flex;
							font-size: 14px;
							margin-bottom: 20px;
							margin-left: 20px;

							.title {
								font-weight: bold;
								color: #333;
								padding: 5px 10px;
								min-width: 90px;
							}

							.info {
								span {
									margin: 0 20px;
								}

								.info-a {
									cursor: pointer;
									padding: 5px 10px;
									margin-right: 10px;
								}

								.info-Select {
									color: #fff;
									background-color: #2778f8;
									border-radius: 4px;
								}
							}

							.el-dropdown-link {
								cursor: pointer;
							}
						}
					}

					.position-tab-content {

						width: 100%;
						background: #fff;
						padding-top: 10px;

						.resume {
							width: 95%;
							padding: 20px;
							box-sizing: border-box;
							background: white;
							margin-bottom: 10px;
							cursor: pointer;
							margin-left: 2.5%;

							border: 1px solid #eee;

							.resumeLeft {
								width: 88%;

								.left {
									width: 100%;
									display: flex;
									// border-right: 1px solid #edf1f5;

									.pic {
										width: 85px;
										height: 85px;
										// border-radius: 50%;
										overflow: hidden;
										margin-right: 20px;
										border: 1px solid #EEE;

										.el-image {
											width: 85px;
											height: 85px;
										}

										// img {
										//     width: 85px;
										//     height: 85px;
										//     display: inline-block;
										// }
									}

									.personal {
										display: flex;
										flex-direction: column;
										justify-content: center;

										.name {
											color: #000;
											font-weight: bold;
											font-size: 16px;
											margin-bottom: 10px;

											.nv {
												color: rgb(220, 0, 29);
												margin-left: 10px;
											}

											.nan {
												margin-left: 10px;
											}

											.brisk {
												font-size: 14px;
												color: #666;
												font-weight: normal;
												margin-left: 15px;
											}
										}

										.salary {
											margin-right: 20px;
											color: #ff7630;
											font-size: 16px;
										}

										.company_profile {
											color: #898989;
											font-size: 14px;

											span {
												margin-right: 10px;
											}
										}

										.hot_move {
											color: #149eff;
											font-size: 14px;
											font-family: SourceHanSansSC-regular;
											margin-top: 10px;
											display: flex;

											a {
												color: #149eff;
												text-decoration: none
											}

											span {
												// margin-right: 10px;
											}

											span:first-child {
												color: #898989;
											}

											.zheye {
												width: 460px;
												white-space: nowrap;
												overflow: hidden;
												text-overflow: ellipsis;

												a {
													margin-right: 10px;
												}

											}
										}

										.resumeFactor {
											span {
												margin: 0 5px;
												font-size: 16px;
												font-weight: bold;
											}
										}
									}
								}

								.right {
									width: 40%;
									margin-left: 20px;

									.state {
										color: #666;
										font-size: 14px;

										.top {
											margin-bottom: 15px;
										}
									}
								}
							}

							.resumeRight {
								width: 12%;
								font-size: 14px;

								.follow {
									width: 80px;
									height: 30px;
									background: #0096ff;
									color: #fff;
									text-align: center;
									line-height: 30px;
									margin-bottom: 12px;
								}

								span {
									width: 80px;
									height: 32px;
									color: #898989;
									font-size: 14px;
									text-align: right;
									font-family: SourceHanSansSC-regular;
									//    background: red;
									margin-left: 8px;


								}

								.talk {
									color: #fb6703;
									margin-bottom: 10px;

									img {
										width: 22px;
										height: 22px;
										margin-right: 5px;
									}
								}

								.collect {
									color: #276bf2;

									i {
										margin-right: 5px;
									}

									.iconoff {
										font-size: 24px;
									}

									.iconon {
										font-size: 28px;
									}
								}

								.talk:hover,
								.collect:hover {
									cursor: pointer;
								}
							}
						}

						.resume:hover {
							box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
						}
					}

					.position-other {
						width: 310px;
						margin-left: 12px;

						.edit-resume {
							padding: 16px 24px;
							background: #fff;
							margin-bottom: 12px;

							a {
								color: #66b1ff;
								padding: 12px;
								background: #fff;
								border: 1px solid #66b1ff;
								display: block;
								text-decoration: none;
							}
						}

						.other-ad {
							margin-bottom: 12px;
							display: flex;

							img {
								width: 100%;
							}
						}

						.other-copmpany {
							background: #fff;
							padding: 16px;

							.other-company-title {
								font-size: 18px;
								color: #333;
								padding-bottom: 16px;
								margin-bottom: 16px;
								text-align: left;
								border-bottom: 1px solid #eee;
							}

							.other-company-cell {
								display: flex;
								flex-wrap: wrap;
								justify-content: center;

								.company-item {
									width: 275px;
									padding: 12px 0;
									background: #f2f2f2;
									margin-bottom: 16px;
									position: relative;
									overflow: hidden;
									cursor: pointer;

									.company-logo {
										height: 50px;
										text-align: center;

										img {
											height: 50px;
										}
									}

									.company-name {
										font-size: 14px;
										line-height: 32px;
										color: #333;
										text-align: center;
										width: 70%;
										margin: 0 auto;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									}

									.company-num {
										padding: 8px 0px;
										border-radius: 2px;
										border: 1px solid #eee;
										font-size: 12px;
										background: #fff;
										width: 180px;
										margin: 0px auto;
										text-align: center;

										b {
											color: #ff0000;
										}
									}
								}

								.company-item:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}


				.recent {
					width: 35%;
					margin-left: 2%;

					.recentBrowsing {
						width: 100%;
						// height: 455px;
						background-color: #fff;

						.title {
							color: rgba(16, 16, 16, 100);
							font-size: 16px;
							width: 97%;
							height: 50px;
							border-bottom: 1px solid #eee;
							margin-left: 3%;
							line-height: 50px;

							span {
								margin-left: 14px;
							}
						}

						.occupation {
							cursor: pointer;
							color: rgba(16, 16, 16, 100);
							font-size: 14px;
							width: 94%;
							height: 100px;
							border-bottom: 1px solid #eee;
							margin-left: 3%;
							margin-right: 3%;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.occupation_company {
								margin-left: 15px;
							}

							.salary {
								margin-right: 15px;
								color: #ff7630;
								font-size: 16px;

							}

						}
					}

					.advertisement {
						width: 100%;
						height: 252px;
						background: #0096FF;
						margin-top: 16px;
					}

				}
			}

		}
	}
</style>
