<template>
	<div style="width: 100%;">
		<Header></Header>
		<div style="width: 1000px; margin:0 auto;">
			<div class="top-search-form">
				<div class="top-search-form-item">
					<div style="display:flex;flex:1">
						<div>
							<div class="top-search-form-item-select">企业</div>
						</div>
						<div class="top-search-form-item-input">
							<el-input v-model="searchKey" placeholder="请输入要搜索的关键字"></el-input>
						</div>
					</div>

				</div>
				<div style="margin-left: 20px;">
					<cu-button color="#307AE8" :fontSize="16" :paddingLr="45" :height="44" text="搜索"
						@click.native="topSearch()"></cu-button>
					<!-- <el-button type="primary" @click="topSearch()">搜索</el-button> -->
				</div>
			</div>
			<div class="hot-position">
				<span>热搜企业：</span>
				<a @click="goto('findEnterprise?searchKey='+item.searchKey,{},2)" v-for="(item,index) in searchlist"
					:key="index">{{item.searchKey}}</a>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from './header.vue'
	import CuDropSelect from "./CuDropSelect.vue";
	import CuButton from './CuButton.vue';
	import {
		isnull
	} from "@/assets/js/index";
	import {
		getsearch,
		addsearch
	} from "@/api/home";
	export default {
		components: {
			Header,
			CuDropSelect,
			CuButton
		},
		data() {
			return {
				searchKey: '',
				searchType: 2,
				searchlist: [],
				usertype: 0,
				userinfo: "",
				userid: 0,
			}
		},

		created() {
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
				this.usertype = this.userinfo.userType;
				if (this.usertype == 1)
					this.userid = this.userinfo.per_id;
				else
					this.userid = this.userinfo.com_id;
			}
		},
		mounted() {
			this.searchKey = this.$route.query.searchKey;
			this.getsearchlist()
		},
		methods: {
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
			topSearch() {
				this.addsearch();
				this.resolvewin('/findEnterprise', {
					searchKey: this.searchKey
				}, 2);
			},
			getsearchlist() {
				var _this = this;
				var per = {
					cid: _this.searchType
				}
				getsearch(per).then((res) => {
					if (res.success)
						this.searchlist = res.data
				})
			},
			addsearch() {
				var _this = this;
				var per = {
					idType: _this.usertype,
					userId: _this.userid,
					searchType: _this.searchType,
					searchKey: _this.searchKey,
				}
				addsearch(per).then((res) => {
					
				})
			}
		},

	};
</script>

<style lang="scss" scoped>
	.hot-position {
		padding-top: 10px;
		padding-left: 40px;
		padding-bottom: 20px;

		span {
			padding: 0 10px;
			font-size: 14px;
			color: #898989;
			font-weight: bold;
		}

		a {
			color: #0096FF;
			padding: 0 10px;
			font-size: 14px;
		}
	}

	.top-search-form {
		padding-top: 30px;
		display: flex;

		.el-select ::v-deep {
			.el-input {
				// padding-top: 4px;
				padding-bottom: 4px;

				.el-input__inner {
					border: none;
					border-radius: 0;
					font-size: 14px;

				}

				.el-input__inner::placeholder {
					color: #333;
				}
			}
		}


		.top-search-form-item {
			margin-bottom: 0;
			flex: 1;
			display: flex;
			border: 2px solid #307AE8;
			background: #FFFFFF;
			padding: 10px 0;

			.top-search-form-item-select {
				height: 20px;
				line-height: 20px;
				padding: 0 16px;
			}

			.top-search-form-item-input {
				flex: 1;

				.el-input ::v-deep {
					padding: 0;
					border: none;

					.el-input__inner {
						border: none;
						border-radius: 0;
						border-left: 1px solid #eee;
						height: 20px;

					}
				}
			}
		}

		.el-button {
			width: 100%;
			height: 40px;
			color: #fff;
			text-align: center;
			border-radius: 0;
			// padding-top: 16px;
			border: 1px solid #288EFC;
			font-size: 16px;
			background: #288EFC;
		}
	}
</style>
